import React, { useState } from 'react'

const calculateStandard = (ogSG, fgSG) => {
	return (ogSG - fgSG) * 131.25
}

const calculateAlternate = (ogSG, fgSG) => {
	return (76.08 * (ogSG-fgSG) / (1.775-ogSG)) * (fgSG / 0.794)
}

const calcualteApparentAttenuation = (ogSG, fgSG) => {
	const og = 1000 * (ogSG-1)
	const fg = 1000 * (fgSG-1)
	return (og - fg) / og
}

const sgToPlato = (sgValue) => {
	return -616.868 + ( 1111.14 * sgValue ) - ( 630.272 * Math.pow(sgValue, 2) ) + ( 135.997 * Math.pow(sgValue, 3) )
}

const platoToSG = (pValue) => {
	return 1.0 + ( pValue / ( 258.6 - ( ( pValue / 258.2 ) * 227.1 ) ) )
}

const ABVCalculator = () => {

	const [gravityUnit, setGravityUnit] = useState(`sg`)
	const [originalGravity, setOriginalGravity] = useState(0)
	const [finalGravity, setFinalGravity] = useState(0)
    const [equation, setEquation] = useState(`standard`)
	const [output, setOutput] = useState({
		ogSG: 0,
		ogP: 0,
		fgSG: 0,
		fgP: 0,
		abvStandard: 0,
		abvAlternate: 0,
		apparentAttenuation: 0,
		calories: 0,
	})
	
    const calculate = e => {
		e.preventDefault()

		const data = {}

		if (gravityUnit === `sg`) {
			data.ogSG = parseFloat(originalGravity)
			data.ogP = sgToPlato(data.ogSG)
			data.fgSG = parseFloat(finalGravity)
			data.fgP = sgToPlato(data.fgSG)
		}

		if (gravityUnit === `plato`) {
			data.ogP = parseFloat(originalGravity)
			data.ogSG = platoToSG(data.ogP)
			data.fgP = parseFloat(finalGravity)
			data.fgSG = platoToSG(data.fgP)
		}

		data.abvStandard = calculateStandard(data.ogSG, data.fgSG)
		data.abvAlternate = calculateAlternate(data.ogSG, data.fgSG)
		data.apparentAttenuation = calcualteApparentAttenuation(data.ogSG, data.fgSG)
		setOutput(data)
	}
	
    return (
		<form className="" onSubmit={calculate}>

			<div className="columns">
				<div className="column">

					<div className="field">
						<label className="label">Gravity Unit</label>

						<label htmlFor="gravityUnitSG" className="radio">
							<input
								id="gravityUnitSG"
								type="radio"
								name="gravityUnit"
								value={`sg`}
								checked={`sg` === gravityUnit}
								onChange={() => setGravityUnit(`sg`)}
							/>
							{` `}
							SG (1.xxx)
						</label>

						<label htmlFor="gravityUnitPlato" className="radio">
							<input
								id="gravityUnitPlato"
								type="radio"
								name="gravityUnit"
								value={`plato`}
								checked={`plato` === gravityUnit}
								onChange={() => setGravityUnit(`plato`)}
							/>
							{` `}
							Plato °P
						</label>
						<p className="help">Are you entering values in Plato or Specific Gravity?</p>
					</div>

					<div className="field">
						<label className="label" htmlFor="originalGravity">
							Original Gravity (OG)
						</label>
						<div className="control">
							<input
								className="input"
								type="text"
								id="originalGravity"
								placeholder="1.048"
								value={originalGravity}
								onChange={e => setOriginalGravity(e.target.value)}
							/>
						</div>
						<p className="help">Value of original gravity.</p>
					</div>

					<div className="field">
						<label className="label" htmlFor="finalGravity">
							Final Gravity (FG)
						</label>
						<div className="control">
							<input
								className="input"
								type="text"
								id="finalGravity"
								placeholder="1.048"
								value={finalGravity}
								onChange={e => setFinalGravity(e.target.value)}
							/>
						</div>
						<p className="help">Value of final gravity.</p>
					</div>

					<div className="field">
						<label className="label">Equation</label>

						<label htmlFor="equationStandard" className="radio">
							<input
								id="equationStandard"
								type="radio"
								name="equation"
								value={`standard`}
								checked={`standard` === equation}
								onChange={() => setEquation(`standard`)}
							/>
							{` `}
							Standard
						</label>

						<label htmlFor="equationAlternate" className="radio">
							<input
								id="equationAlternate"
								type="radio"
								name="equation"
								value={`alternate`}
								checked={`alternate` === equation}
								onChange={() => setEquation(`alternate`)}
							/>
							{` `}
							Alternate
						</label>
						
						<p className="help">Standard equation is perfect for home brewers, alternate is more precice.</p>

					</div>

					<div>
						<button
							type="submit"
							className="button is-primary"
						>
							Calculate
						</button>
					</div>

				</div>

				<div className="column">

					<div className="level">
						<div className="level-item has-text-centered">
							<div>
								<p className="heading">
									Alcohol By Volume (ABV)
								</p>
								<p className="title">
									{equation === `standard` ? output.abvStandard.toFixed(2) : output.abvAlternate.toFixed(2)}%
								</p>
							</div>
						</div>
						<div
							className="level-item has-text-centered"
						>
							<div>
								<p className="heading">
									Apparent Attenuation
								</p>
								<p className="title">
									{parseInt(output.apparentAttenuation*100)}%
								</p>
							</div>
						</div>
					</div>
					<div className="level" style={{ marginTop: `2rem` }}>
						<div
							className="level-item has-text-centered"
							style={{
								opacity: gravityUnit === `sg` ? 1 : 0.5
							}}
						>
							<div>
								<p className="heading">
									Original Gravity (SG)
								</p>
								<p className="title">{output.ogSG.toFixed(3)}</p>
							</div>
						</div>
						<div
							className="level-item has-text-centered"
							style={{
								opacity: gravityUnit === `plato` ? 1 : 0.5
							}}
						>
							<div>
								<p className="heading">
									Original Gravity (Plato)
								</p>
								<p className="title">{output.ogP.toFixed(2)} °P</p>
							</div>
						</div>
					</div>
					<div className="level" style={{ marginTop: `2rem` }}>
						<div
							className="level-item has-text-centered"
							style={{
								opacity: gravityUnit === `sg` ? 1 : 0.5
							}}
						>
							<div>
								<p className="heading">
									Final Gravity (SG)
								</p>
								<p className="title">{output.fgSG.toFixed(3)}</p>
							</div>
						</div>
						<div
							className="level-item has-text-centered"
							style={{
								opacity: gravityUnit === `plato` ? 1 : 0.5
							}}
						>
							<div>
								<p className="heading">
									Final Gravity (Plato)
								</p>
								<p className="title">{output.fgP.toFixed(2)} °P</p>
							</div>
						</div>
					</div>

				</div>
			</div>


		</form>
	)
}

ABVCalculator.propTypes = {}

ABVCalculator.defaultProps = {}

export default ABVCalculator
