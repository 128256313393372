import React from 'react'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import ABVCaculator from '../../tools/ABVCaculator'

const ABVCalculatorPage = () => (
    <Layout>
        <SEO title="ABV Calculator – Plato & Standard Gravity Alcohol By Volume Calculator" />
		<section className="hero is-primary">
            <div className="hero-body">
                <div className="container is-fluid">
                    <h1 className="title">Online ABV Calculator</h1>
                    <h2 className="subtitle">
                        This easy-to-use alcohol by volume calculator allows you to get the ABV based on gravity change. You can use the simple standard equation that is loved by home beer brewers; or the alternate more compex but more precise formula. Our ABV calculator works with either Plato or Standard Gravity units. 
                    </h2>
                </div>
            </div>
        </section>

		<section className="section">
            <div className="container is-fluid">
				<ABVCaculator />
            </div>
        </section>

		<section className="section">
			<div className="container">
				<h1 className="title">Standard ABV Formula</h1>
				<div className="panel">
					<p className="panel-heading">Formula</p>
					<p className="panel-block">
						The standard formula to get the ABV based on gravity change is:
					</p>
					<pre className="panel-block" style={{ fontSize: `1em` }}>
						abv = (original in SG - final) in SG * 131.25
					</pre>
					<p className="panel-block">
						While slightly less precise, the standard ABV equation is the most usable for home brewers who aren't into mathematics.
					</p>
				</div>
			</div>
		</section>

		<section className="section">
			<div className="container">
				<h1 className="title">Alternate ABV Formula</h1>
				<div className="panel">
					<p className="panel-heading">Formula</p>
					<p className="panel-block">
						The alternate, more complex formula to get the ABV based on gravity change is:
					</p>
					<pre className="panel-block" style={{ fontSize: `1em` }}>
						abv = ( 76.08 * ( original in SG - final in SG ) / ( 1.775 - original in SG ) ) * ( final in SG / 0.794 )
					</pre>
					<p className="panel-block">
						This is an advanced equation that provides a more accurate alcohol by volume measure. Especially with more alcoholic brews as the differences in precision grows between both methods.
					</p>
				</div>
			</div>
		</section>

    </Layout>
)

export default ABVCalculatorPage
